import { apiGet } from '@/utils/api';
import errorPopup from '@/utils/error-popup';

export default (userId, payload) => {
	const parameters = {
		dateFrom: payload.dateFrom !== null ? payload.dateFrom : '',
		dateTo: payload.dateTo !== null ? payload.dateTo : '',
		projectId: payload.projectId !== null ? payload.projectId : '',
		tagIds: payload.tagIds !== null ? payload.tagIds : '',
		sort: payload.sort !== null ? payload.sort : '',
		sortDesc: payload.sortDesc !== null ? payload.sortDesc : false,
	}

	return apiGet(`users/${userId}/time-entries-unpaginated?dateFrom=${parameters.dateFrom}&dateTo=${parameters.dateTo}&projectId=${parameters.projectId}&tagIds=${parameters.tagIds}&sort=${parameters.sort}&sortDesc=${parameters.sortDesc}`)
		.then((response => response.data))
		.catch(error => errorPopup(error));
}