<template>
	<Layout>
		<PageHeader
			:title="title"
			v-bind="actionButton"
			@openCreateTimeEntryModal="toggleCreateTimeEntryModal"
		/>
		<TimeEntriesTable
			@editTimeEntry="showEditTimeEntryModal"
			@deleteTimeEntry="openDeleteTimeEntryModal"
			@pageChanged="fetchTableData"
			@sortChanged="fetchTableData"
		/>
		<b-modal
			id="create-time-entry-modal"
			ref="create-time-entry-modal"
			title="Create Time Entry"
			hide-header
			hide-footer
			no-close-on-backdrop
			centered
		>
			<CreateTimeEntry
				:showCancel="true"
				@cancelCreateTimeEntry="toggleCreateTimeEntryModal"
				@timeEntryCreated="timeEntryCreated"
			/>
		</b-modal>
		<b-modal
			id="edit-time-entry-modal"
			ref="edit-time-entry-modal"
			title="edit Time Entry"
			hide-header
			hide-footer
			no-close-on-backdrop
			centered
		>
			<EditTimeEntry
				:showCancel="true"
				:timeEntryId="editTimeEntryId"
				@cancelEditTimeEntry="hideEditTimeEntryModal"
				@timeEntryEdited="timeEntryEdited"
			/>
		</b-modal>		
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { apiDelete } from "@/utils/api";
import PageHeader from "@/components/page-header";
import TimeEntriesTable from "@/components/client-dashboard/time-entries-table";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import errorPopup from '@/utils/error-popup';
import CreateTimeEntry from '@/components/client-dashboard/create-time-entry';
import EditTimeEntry from '@/components/client-dashboard/edit-time-entry';

export default {
	page: {
		title: "TimeEntries",
		meta: [{ name: "ClientTimeEntries", content: appConfig.description }]
	},
	components: { 
		Layout,
		PageHeader,
		TimeEntriesTable,
		CreateTimeEntry,
		EditTimeEntry,
	},
	data() {
		return {
			title: "Time Entries",
			actionButton: {
				buttonText: 'New Time Entry',
				buttonEvent: 'openCreateTimeEntryModal',
				buttonClasses: 'btn-success',
				iconClasses: 'fas fa-plus',
				isDisabled: false,
				tooltip: null,
			},
			subscription: null,
			editTimeEntryId: null,
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		tableData() {
			return this.$store.state.timeEntriesTable.tableData;
		},
		currentPage() {
			return this.$store.state.timeEntriesTable.currentPage;
		},
		sort() {
			return this.$store.state.timeEntriesTable.sort;
		},
		sortDesc() {
			return this.$store.state.timeEntriesTable.sortDesc;
		},
	},
	watch: {
		userInfo(userInfo) {
			this.$store.dispatch('timeEntriesTable/update', { userId: userInfo.id, page: 1, sort: this.sort, sortDesc: this.sortDesc ? 1 : 0 });
			this.$store.dispatch('tagsTable/update', { userId: userInfo.id, page: 1, sort: 'id', sortDesc: 1 });
		},
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	},
	methods: {
		updatePage(page) {
			this.$store.dispatch('timeEntriesTable/update', { userId: this.userInfo.id, page, sort: this.sort, sortDesc: this.sortDesc ? 1 : 0 })
		},
		fetchTableData(page, sort, sortDesc) {
			this.$store.dispatch('timeEntriesTable/update', {
				userId: this.userInfo.id,
				page,
				sort,
				sortDesc,
				})
		},
		toggleCreateTimeEntryModal() {
			this.$refs['create-time-entry-modal'].toggle();
		},
		timeEntryCreated() {
			this.$bvModal.hide('create-time-entry-modal');
			Swal.fire({
				icon: "success",
				title: "Success!",
				html: `Time entry created.`,
				timer: 1000,
				showConfirmButton: false,
			});
			this.updatePage(this.currentPage);
		},
		showEditTimeEntryModal(event, timeEntryId) {
			this.editTimeEntryId = timeEntryId;
			this.$bvModal.show('edit-time-entry-modal');
		},
		hideEditTimeEntryModal(event) {
			this.$bvModal.hide('edit-time-entry-modal');
		},
		timeEntryEdited() {
			this.$bvModal.hide('edit-time-entry-modal');
			Swal.fire({
				icon: "success",
				title: "Success!",
				html: `Time entry updated.`,
				timer: 1000,
				showConfirmButton: false,
			});
			this.updatePage(this.currentPage);
		},
		openDeleteTimeEntryModal(event, timeEntryId) {
			Swal.fire({
				title: "Delete Time Entry",
				text: "Are you sure you want to delete this time entry?",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				preConfirm: () => {
					this.deleteTimeEntry(timeEntryId);
				},
				allowOutsideClick: true,
			});
		},
		deleteTimeEntry(timeEntryId) {
			apiDelete(`time-entries/${timeEntryId}`)
				.then(() => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `Time entry deleted.`,
						timer: 1000,
						showConfirmButton: false,
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
	},
};
</script>
