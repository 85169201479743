<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">
						Time Entries Table
					</h4>
					<div class="row mb-md-2" />
					<div v-if="tableData && tableData.length">
						<div
							class="table-responsive mb-0"
						>
							<b-table
								:items="tableData"
								:fields="fields"
								responsive="sm"
								:per-page="perPage"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								:no-local-sorting="true"
								@sort-changed="sortingChanged"
								:table-class="'table-centered'"
							>
								<template #cell(action)="data">
									<span
										v-b-tooltip.hover.bottom="{ customClass: 'tooltip' }"
										:title="belongsToArchivedProject(data.item) ? `Can't edit time entry for an archived project.` : ''"
									>
										<button
											class="btn btn-primary table-button"
											@click="$emit('editTimeEntry', $event, data.item.id)"
											:disabled="belongsToArchivedProject(data.item)"
										>
											<i class="fas fa-pencil-alt" />
											Edit
										</button>
									</span>
									<span
										v-b-tooltip.hover.bottom="{ customClass: 'tooltip' }"
										:title="belongsToArchivedProject(data.item) ? `Can't delete time entry for an archived project.`: ''"
									>
										<button
											class="btn btn-danger table-button"
											@click="$emit('deleteTimeEntry', $event, data.item.id)"
											:disabled="belongsToArchivedProject(data.item)"
										>
											<i class="far fa-trash-alt" />
											Delete
										</button>
									</span>
								</template>
								<template #cell(ProjectName)="data">
									<div v-if="data.item.project">
										{{ data.item.project.project_name }}
									</div>
								</template>
								<template #cell(Tags)="data">
									<div v-if="data.item.tags.length">
										<span
											v-for="tag in data.item.tags"
											:key="tag.id"
										>
											<b-badge
												class="badge"
												variant="primary"
											>
												#{{ tag.text }}
											</b-badge>
										</span>
									</div>
								</template>
								<template #cell(duration)="data">
									<span>{{ formatDuration(data.item.duration) }}</span>
								</template>
								<template #head(action)="field">
									<span></span>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-right">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination
											v-model="currentPage"
											:total-rows="totalItemsCount"
											:per-page="perPage"
											@input="getPaginatedTableData(currentPage)"
										/>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="tableData">
						<h2 class="text-center empty-table">Nothing here yet!</h2>
						<p class="text-center text-muted">Try creating a project and some time entries to get started.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appConfig from "@/app.config";
import formatDuration from '@/utils/format-duration';

export default {
	name: 'TimeEntriesTable',
	page: {
		title: "Time Entries Table",
		meta: [{ name: "ClientReportsTable", content: appConfig.description }]
	},
	data() {
		return {
			title: "Time Entries Table",
			currentPage: 1,
			perPage: 15,
			sortBy: "date",
			sortDesc: true,
			fields: [
				{ key: "duration", sortable: true },
				'ProjectName',
				'Tags',
				{ key: "date", sortable: true },
				'action',
			],
		};
	},
	computed: {
		tableData() {
			return this.$store.state.timeEntriesTable.tableData;
		},
		totalItemsCount() {
			return this.$store.state.timeEntriesTable.totalItemsCount;
		},
	},
	methods: {
		getPaginatedTableData(currentPage) {
			this.$emit('updatePage', currentPage);
			this.$emit('pageChanged', currentPage, this.sortBy, this.sortDesc ? 1 : 0);
		},
		sortingChanged(ctx) {
			this.$emit('sortChanged', this.currentPage, ctx.sortBy, ctx.sortDesc ? 1 : 0);
		},
		formatDuration(minutes) {
			return formatDuration(minutes);
		},
		belongsToArchivedProject(timeEntry) {
			return timeEntry.project && timeEntry.project.archived_at;
		},
	}
};
</script>

<style scoped>
.table-button {
	margin-right: 5px;
}

i {
	margin-right: 5px;
}

.badge {
	margin-right: 5px;
	cursor: default;
}

.tooltip {
	cursor: default;
}

.empty-table {
	margin: 50px 0;
	color: #9ca8b3;
}
</style>